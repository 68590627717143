.about-page {
    .app-logo {
        width: 200px;
    }

    .company-logo {
        width: 130px;
    }

    .feedback {
        margin: 30px 0;
        background: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
        padding: 20px;

        a {
            color: var(--ion-color-primary-contrast);
        }
    }

    .about-developer {
        margin-top: 30px;
    }
}