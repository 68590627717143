.input-story-name {
    font-size: 28px;
    font-weight: 600;
}

.story-page {
  .lock-icon {
    float: right;

    &.locked {
      color: rgba($color: #000000, $alpha: 0.8);
    }

    &.unlocked {
      color: rgba($color: #000000, $alpha: 0.3);
    }
  }
}
