.button-generate-story {
    width: 250px;
    height: 250px;
    margin: 0 auto;
    overflow: hidden;
    --border-radius: 250px;
    border-radius: 250px;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    font-size: 20px;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.4);

    &:active, &:hover, &:focus {
        border-radius: 250px;
    }
}

.button-past-stories {
    margin-top: 20px;
    display: block;
}

.page-generator {
    .page-generator-grid {
        display: flex;
        flex-direction: column;
    }

    .centered-content-wrapper {
        flex: 1;
    }

    .icon-reload {
        max-width: 200px;
        padding: 25px;
    }

    .marketing-message {
        display: block;
        text-decoration: none;
        padding: 10px;
        text-align: center;
        color: white;
        background-color: var(--ion-color-tertiary);
    }
}