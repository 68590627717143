.page-config {
  .pro-status {
    padding: 10px;
    text-align: center;
    display: block;
    color: white;
    background-color: var(--ion-color-tertiary);
    text-decoration: none;
    margin-bottom: 10px;
  }

  .pro-status.enabled {
    background-color: var(--ion-color-success);
    color: var(--ion-color-success-contrast);
  }
}