@import './variables';

.responsive-page {
    max-width: $maxPageWidth;
    margin: 0 auto;
}

.full-height {
    height: 100%;
}

.content-full-center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-icon-toolbar {
    width: 35px;
    height: 35px;
}

.header-no-shadow:after {
    background-image: none !important;
}

.text-center {
    text-align: center;
}

[contenteditable]::before, .pencil-edit::before {
    content: '';
    display: inline-block;
    top: 10px;
    background: no-repeat center center;
    background-size: contain;
    background-image: url(/assets/edit.svg);
    padding: 8px;
    margin-right: 10px;
}

.content-center {
    text-align: center;
}

.icon-reload {
    width: 100%;
    padding: 15px;
}

.shadow-soft {
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.4);
}
